<template>
    <div id="header">
        <div class="header">
            <img src="../assets/logo_eee_4c_r.png" />
            <FormulateInput type="button" @click="$emit('reset')" value="NEW SEARCH"/>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.header {
  width: 100%;
  position: fixed;
  height: 60px;
  background-color: #fcfcfc;
  border-bottom: 1px solid rgba(60,60,60,.3);
  border-top: 10px solid #d2492a;
  display:flex;
  justify-content: space-between;
  align-items: center;
  & .formulate-input-element {
    margin: 0 40px 0 0;
  }
  & img {
    height: 80%;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .header {
    & img {
      width: 50%;
      height: auto;
      margin-left: 10px;
    }
    & .formulate-input-element {
      margin: 0;
    }
  }
}
</style>