<template>
    <div id="results">
        <div class='nav' v-if="searchResults.previous || searchResults.next">
            <FormulateInput v-if="searchResults.next" type="button" @click="viewNextOrPrev('next')" value="SEE MORE RESULTS →"/>
            <FormulateInput v-if="searchResults.previous" type="button" @click="viewNextOrPrev('previous')" value="← SEE PREVIOUS RESULTS"/>
        </div>
        <ul>
            <li v-if="searchResults.count == 0" style="text-align:center;">No results found for this search.</li>
            <li v-for="(result,idx) in searchResults.results" :key="idx">
                <a href="#" @click="$emit('single',result)">{{result.full_name}} (Class year: {{result.class_year}})</a>
            </li>
        </ul>
        <div class='nav' v-if="searchResults.previous || searchResults.next">
             <FormulateInput v-if="searchResults.next" type="button" @click="viewNextOrPrev('next')" value="SEE MORE RESULTS →"/>
            <FormulateInput v-if="searchResults.previous" type="button" @click="viewNextOrPrev('previous')" value="← SEE PREVIOUS RESULTS"/>
        </div>
        <FormulateInput type="button" @click="$emit('reset')" value="NEW SEARCH"/>
        <FormulateInput type="button" @click="$emit('add',{new: 'alumni'})" value="ADD NEW ALUMNUS/ALUMNA"/>
    </div>
</template>

<script>
export default {
    props: {
        searchResults: Object,
    },
    methods: {
        viewNextOrPrev(direction) {
            this.$emit('direction',direction);
        },
    }
}
</script>

<style lang="scss" scoped>
ul {
  width: 35%;
  margin: 0 auto;
  padding: 0;
}
li {
  list-style-type: none;
  margin-bottom: 1em;
}
.nav {
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin: 0 auto 2em;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 800px) {
    ul, .nav {
        width: 80%;
    }
}
@media only screen and (max-width: 400px) {
    .nav > div {
        margin: 2px;
    }        
}
</style>