<template>
  <div class="home">
    <Header @reset="reset" />
    <div id="main">
      <SingleResult v-if="personResult != null" :person="personResult" @reset="reset" @clearPerson="personResult = null" />
      <PreviewResults v-else-if="searchResults != null" :searchResults="searchResults" @direction="viewNextOrPrev" @reset="reset" @single="setPersonResult" @add="setPersonResult" />
      <FormulateForm v-else @submit="search">
        <h2>Colorado School of Mines Alumni Information Update</h2>
        <p>Search for your name and let us know what you've been up to since graduating, add a photo or share your words of advice with today's students.</p>
        <p>And stop by the interactive Grad Wall on the second floor of the Student Center the next time you're on campus.</p>
        <FormulateInput type="text" name="first_name" label="First name:" />
        <FormulateInput type="text" name="last_name" label="Last name:" />
        <FormulateInput type="submit" label="SEARCH"/>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import PreviewResults from "@/components/PreviewResults";
import SingleResult from "@/components/SingleResult";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "Home",
  components: {
    PreviewResults,
    SingleResult,
    Header
  },
  data() {
    return {
      searchResults: null,
      personResult: null
    }
  },
  methods: {
    search({first_name,last_name}) {
      let query = "";
      if (last_name) {
        query += "?last_name_exact=" + last_name; 
      }
      if(first_name) {
        query != "" ? query += "&" : query += "?";
        query += "first_name_starts_with=" + first_name;
      }
        if (query != "") {
        axios.get("https://di-mines.herokuapp.com/api/alumni/" + query).then((res) => {
          this.searchResults = res.data
        });
      }
    },
    viewNextOrPrev(direction) {
      axios.get(this.searchResults[direction]).then((res) => {
        this.searchResults = res.data
      });
    },
    reset() {
      this.searchResults = null;
      this.personResult = null;
    },
    setPersonResult(person) {
      this.personResult = person;
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}
#main {
  padding-top: 100px;
}
h2 {
  color: rgb(60,60,60);
  text-align: center;
}
p {
  &:last-of-type {
    margin-bottom: 50px;
  }
  text-align: center;
  font-size: 18px;
}
</style>
