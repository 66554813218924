<template>
    <div>
        <FormulateInput type="button" @click="$emit('clearPerson')" value="← BACK TO RESULTS" />
        <div v-if="thanks == null">
            <FormulateForm  @submit="submitUpdate">
                <FormulateInput 
                    v-for="(field, idx) in alumniProps" 
                    :key="idx" 
                    :type="field.type" 
                    :name="idx" 
                    :label="field.label" 
                    :value="person[idx]" 
                    :placeholder="field.placeholder" 
                    :validation="field.validation" 
                    :options="field.options" 
                    :help="field.help"
                />
                <FormulateInput type="file" name="headshot" label="Headshot" :uploader="uploadHeadshot" />
                <FormulateInput v-if="!isNew" type="file" name="photos" label="Photos" :uploader="uploadPhoto" />
                <FormulateInput type="submit" @submit="submitUpdate" value="SUBMIT" />
            </FormulateForm>
            <div class="asterisk">
                <span>*Disallowed content:</span>
                <ul>
                    <li>Advertising a company or cause</li>
                    <li>Containing defamatory, obscene or offensive language</li>
                    <li>Charged language based on race, religion, ethnicity, gender, age, sexual orientation or any physical or mental disability</li>
                    <li>False, misleading or deceptive</li>
                </ul>
            </div>
        </div>
        <div v-else style="text-align:center">
            <h1>Thank you for submitting!</h1>
            <p>Your submission will be added once it has been approved.</p>
            <FormulateInput type="button" @click="$emit('reset')" value="SEARCH AGAIN" />
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        person: Object,
    },
    data() {
        return {
            alumniProps: {
                last_updated_by_email: {type: 'email', label: "Your Email", placeholder: "email@email.com", validation: "required", help: "Email for internal use only – not for display"},
                full_name: {type: 'text', label: "Full Name", placeholder: "Dr. Jane L. Doe", validation: "required", help: ""},
                first_name: {type: 'text', label: "First Name", placeholder: "Jane", validation: "required", help: ""},
                middle_name: {type: "text", label: "Middle Name", placeholder: "Lea", validation: "", help: ""},
                last_name: {type: "text", label: "Last Name", placeholder: "Doe", validation: "required", help: ""},
                class_year: {type: 'text', label: "Class Year", placeholder: new Date().getFullYear(), validation: "required|number", help: ""},
                biography: {type: 'textarea', label: "Biography", placeholder: "Write a little about this person", validation: "", help: ""},
                quote: {type: 'textarea', label: "Quote", placeholder: "A notable quote from this person", validation: "max:255", help: "All quotes will be reviewed by the Foundation for content that is not permitted*"},
                degrees: {type: 'text', label: "Degrees", placeholder: "Bachelor of Science in Engineering '09", validation: "required", help: ""},
            },
            thanks: null,
            file: null
        }
    },
    methods: {
        submitUpdate(data) {
            let req = {}, formData = new FormData();
            Object.entries(data).forEach((field) => { 
                field[1] === false ? req[field[0]] = null : req[field[0]] = field[1];
            });
            formData.append('last_updated_by_email', req.last_updated_by_email);
            formData.append('full_name', req.full_name);
            formData.append('first_name', req.first_name);
            formData.append('middle_name', req.middle_name);
            formData.append('last_name', req.last_name);
            formData.append('biography', req.biography);
            formData.append('quote', req.quote);
            formData.append('class_year', req.class_year);
            formData.append('degrees', req.degrees);
            if (this.file != null) {
                formData.append('headshot', this.file)
            }
            if (this.isNew) {
                axios.post("https://di-mines.herokuapp.com/api/alumni/", formData).then(() => {
                    this.thanks = setTimeout(() => {
                        this.$emit('reset');
                        clearTimeout(this.thanks);
                    }, 30000)
                }).catch((error) => {console.log(error)});
            } else {
                formData.append('id', this.person.id);
                axios.put("https://di-mines.herokuapp.com/api/alumni/"+this.person.id+"/", formData).then(() => {
                    this.thanks = setTimeout(() => {
                        this.$emit('reset');
                        clearTimeout(this.thanks);
                    }, 30000)
                }).catch((error) => {console.log(error)});
            }
        },
        uploadHeadshot(file) {
            this.file = file;
        },
        uploadPhoto(file) {
            try {
                let formData = new FormData();
                formData.append('image', file)
                formData.append('alumnus', this.person.id);
                axios.post("https://di-mines.herokuapp.com/api/alumni-photo/", formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
    computed: {
        isNew() {
            return this.person.new == undefined ? false : true;
        }
    },
    mounted() {
        this.file = null;
        this.person.last_updated_by_email = "";
    }
}
</script>

<style lang="scss" scoped>
.asterisk {
    font-size: .7em;
    margin: 50px 10px;
    text-align: center;
    & span {
        display: block;
    }
    & ul {
        margin: 0 auto;
        display: inline-block;
        text-align: left;
    } 
}
</style>
